import Button from "../../components/Button/Button";

const Rsvp = ({ deadline, onClick, RSVP_BY }) => {
  return (
    <>
      <Button onClick={onClick}>RSVP</Button>
      <p className={deadline}>PLEASE RSVP BY {RSVP_BY} </p>
    </>
  );
};

export default Rsvp;
