import { Fragment, forwardRef, useRef, useState } from "react";

import classes from "./Form.module.scss";
import "react-phone-number-input/style.css";

import FormSubmitted from "./FormSubmitted";
import Button from "../Button/Button";
import PhoneInput from "react-phone-number-input";

// forwardRef allows the parent design to pass a ref to Form, which handles the "scrollIntoView" behaviour
const Form = forwardRef((props, formContainerRef) => {
  const encode = (data) => {
    // No idea what this does, currently
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  };

  // set state
  const [submitted, setSubmitted] = useState(false);
  const [rsvpResponse, setRsvpResponse] = useState(false);
  const [name, setName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [isPhoneEmpty, setIsPhoneEmpty] = useState(false);
  const [numOfGuests, setNumOfGuests] = useState(0);
  const [showDietaryRequirements, setShowDietaryRequirements] = useState({});

  // Grab input refs
  const formRef = useRef();
  const firstNameInputRef = useRef();
  const surnameInputRef = useRef();
  const attendingInputRef = useRef();
  const unavailableInputRef = useRef();
  const emailInputRef = useRef();
  const phoneNumberInputRef = useRef(); // not used, state is passed
  const leadGuestDietaryReqInputRef = useRef();
  const numOfGuestsInputRef = useRef();
  const additionalGuestInputRef = useRef([]);
  const showDietReqInputRef = useRef([]);
  const dietaryReqInputRef = useRef([]);
  const messageInputRef = useRef();

  // Additional Guests & dietary requirement logic
  const MAX_NUM_OF_GUESTS = 8;

  let additionalGuestArray = [];
  for (let i = 1; i <= numOfGuests; i++) {
    if (numOfGuests <= MAX_NUM_OF_GUESTS) {
      additionalGuestArray.push(
        <Fragment key={i}>
          <div className={classes["additional-guest-div"]}>
            <input
              type="text"
              name={`additionalGuest${i}`}
              id="additional-guest"
              ref={(e) => (additionalGuestInputRef.current[i - 1] = e)}
              className={classes.translate}
              placeholder="*Full Name:"
              maxLength={35}
              required
            />

            <label htmlFor={`showDietaryRequirements${i}`}>
              <input
                type="checkbox"
                name="showDietaryRequirements"
                id={`showDietaryRequirements${i}`}
                value={`Requirements${i}`}
                ref={(e) => (showDietReqInputRef.current[i - 1] = e)}
                onChange={(e) => checkBoxClickHandler(e)}
              />
              <span>Dietary Requirements</span>
            </label>
          </div>

          {Object.keys(showDietaryRequirements).map((e) => {
            if (e === `Requirements${i}` && showDietaryRequirements[e] === true) {
              return (
                <textarea
                  name="dietaryRequirements"
                  id="dietaryRequirements"
                  key={`dietReq${i}`}
                  placeholder="Dietary Requirements:"
                  maxLength={100}
                  ref={(e) => (dietaryReqInputRef.current[i - 1] = e)}
                  className={classes.translate}></textarea>
              );
            }
            return "";
          })}
        </Fragment>
      );
    }
  }

  // onClick handler for the "attending" and "unavailable" radio boxes.
  const radioBoxClickHandler = () =>
    attendingInputRef.current.checked ? setRsvpResponse(true) : (setRsvpResponse(false), setShowDietaryRequirements({}));

  const phoneNumberHandler = (value) => {
    if (value && value.length >= 8) {
      setIsPhoneEmpty(false);
    }
    setPhoneNumber(value);
  };

  // additionalGuestListHandler concatenates all additional guests and returns a string containing guests and their dietary requirements
  const additionalGuestListHandler = (arr, arr2) => {
    let additionalGuests = "";
    let counter = {};

    arr.forEach((e, i) => {
      if (e) {
        if (!arr2[i]) {
          counter[`GUEST ${i + 1}`] = `${e.value}. No dietary Requirements.`;
        } else {
          counter[`GUEST ${i + 1}`] = e.value + ". Dietary Requirements: " + arr2[i].value;
        }
      }
    });

    for (const [key, value] of Object.entries(counter)) {
      additionalGuests = additionalGuests.concat(`${key}: ${value} `);
    }

    return additionalGuests;
  };

  const checkBoxClickHandler = (e) => {
    let updated = {};
    updated = { ...showDietaryRequirements, [e.target.value]: e.target.checked };

    setShowDietaryRequirements((dietaryRequirements) => ({
      ...dietaryRequirements,
      ...updated,
    }));
  };

  const submitHandler = (event) => {
    event.preventDefault();

    // Assign inputs to ref values
    const firstName = firstNameInputRef.current.value;
    const leadGuest = attendingInputRef.current.checked
      ? leadGuestDietaryReqInputRef.current && leadGuestDietaryReqInputRef.current.value
        ? `${firstNameInputRef.current.value} ${surnameInputRef.current.value}. Dietary Requirements: ${leadGuestDietaryReqInputRef.current.value}`
        : `${firstNameInputRef.current.value} ${surnameInputRef.current.value}. No dietary Requirements.`
      : `${firstNameInputRef.current.value} ${surnameInputRef.current.value}.`;
    const rsvp = attendingInputRef.current.checked ? attendingInputRef.current.value : unavailableInputRef.current.value;
    const message = messageInputRef.current.value;

    const email = attendingInputRef.current.checked ? emailInputRef.current.value : "";
    const phone = attendingInputRef.current.checked ? phoneNumber : "";
    const numberOfGuests = attendingInputRef.current.checked ? numOfGuestsInputRef.current.value : "";
    const additionalGuestString = additionalGuestListHandler(additionalGuestInputRef.current, dietaryReqInputRef.current);
    const additionalGuests = attendingInputRef.current.checked ? additionalGuestString : "";

    // Custom error handling
    if (!attendingInputRef.current.checked && !unavailableInputRef.current.checked) {
      return setRsvpResponse(null);
    }

    if (attendingInputRef.current.checked && (!phoneNumber || phoneNumber.length <= 8)) {
      return setIsPhoneEmpty(true);
    }

    // Combine ref values in array to be sent to Netlify
    const formValues = { leadGuest, rsvp, email, phone, numberOfGuests, additionalGuests, message };

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": props.FORM_NAME, ...formValues }),
    })
      .then(() => console.log("Success!", formValues))
      .catch((error) => alert(error)); // HANDLE ERROR CASE BEFORE GO LIVE!

    setName(firstName);
    rsvp === "Attending" ? setRsvpResponse(true) : setRsvpResponse(false);
    setSubmitted(true);

    formRef.current.reset();
  };

  return (
    <div className={classes["form-container"]} ref={formContainerRef}>
      {!submitted && (
        <>
          <form name={props.FORM_NAME} method="POST" netlify onSubmit={submitHandler} ref={formRef} className={classes.form}>
            <h2>Will you be joining us?</h2>
            {/* <p>Please submit one per guest</p> */}
            <p>Lead guest details</p>
            <input type="hidden" name="form-name" value={props.FORM_NAME} />

            <div className={classes["name-div"]}>
              <input
                type="text"
                name="firstName"
                id="firstName"
                placeholder="*First name:"
                maxLength={35}
                ref={firstNameInputRef}
                required
              />

              <input type="text" name="surname" id="surname" placeholder="*Surname:" maxLength={35} ref={surnameInputRef} required />
            </div>

            <div className={classes["radio-div"]}>
              <label htmlFor="attending">
                <input
                  type="radio"
                  name="rsvp"
                  id="attending"
                  value="Attending"
                  ref={attendingInputRef}
                  onClick={radioBoxClickHandler}
                  /* required */
                />
                <span>Joyfully Accept</span>
              </label>

              <label htmlFor="unavailable">
                <input
                  type="radio"
                  name="rsvp"
                  id="unavailable"
                  value="Unavailable"
                  ref={unavailableInputRef}
                  onClick={radioBoxClickHandler}
                  /* required */
                />
                <span>Regretfully Decline</span>
              </label>
            </div>

            {rsvpResponse === null ? <p style={{ color: "red", fontSize: "1vw", textTransform: "none" }}>Will you be attending?</p> : ""}

            {rsvpResponse && (
              <>
                <div className={classes["contact-div"]}>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="*Email:"
                    ref={emailInputRef}
                    className={classes.translate}
                    required
                  />

                  <PhoneInput
                    style={{ flex: "1 1 53%" }}
                    defaultCountry="GB"
                    placeholder="*Phone:"
                    className={classes.translate}
                    value={phoneNumber}
                    onChange={phoneNumberHandler}
                    ref={phoneNumberInputRef}
                    required
                  />
                </div>

                {isPhoneEmpty && (
                  <p style={{ color: "red", fontSize: "0.75rem", textTransform: "none" }}> Please enter a valid phone number </p>
                )}

                <textarea
                  name="dietaryRequirements"
                  id="dietaryRequirements"
                  placeholder="Dietary Requirements:"
                  maxLength={100}
                  ref={leadGuestDietaryReqInputRef}
                  className={classes.translate}></textarea>

                <select
                  name="num-of-guests"
                  id="num-of-guests"
                  ref={numOfGuestsInputRef}
                  className={classes.translate}
                  onChange={(e) => setNumOfGuests(e.target.value)}
                  required>
                  <option disabled selected value="">
                    *Number of Additional Guests
                  </option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                </select>

                {numOfGuests <= MAX_NUM_OF_GUESTS && additionalGuestArray}
                {numOfGuests > MAX_NUM_OF_GUESTS ? (
                  <p style={{ color: "red", fontSize: "1vw", textTransform: "none" }}>
                    Party size must be less than or equal to {`${MAX_NUM_OF_GUESTS}`}
                  </p>
                ) : (
                  ""
                )}
              </>
            )}

            <textarea name="message" id="message" placeholder="Leave a note:" maxLength={250} ref={messageInputRef}></textarea>

            <Button type="submit" id="submit">
              Submit
            </Button>
          </form>
        </>
      )}
      {submitted && (
        <FormSubmitted
          rsvpResponse={rsvpResponse}
          setSubmitted={setSubmitted}
          firstName={name}
          DATE_TEXT={props.DATE_TEXT}
          ADDRESS={props.ADDRESS}
        />
      )}
    </div>
  );
});

export default Form;

/*                 
  <input
    type="number"
    name="num-of-guests"
    id="num-of-guests"
    placeholder="Number of Additional Guests:"
    min={0}
    max={MAX_NUM_OF_GUESTS}
    ref={numOfGuestsInputRef}
    onChange={(e) => setNumOfGuests(e.target.value)}
    className={classes.translate}
    required
  /> 

  Old Dietary Requirements based on number of guests/party size
  <select
    name={`meal_${i}`}
    id="meal"
    ref={(e) => (mealInputRef.current[i - 1] = e)}
    key={i}
    className={classes.translate}
    required>
    <option hidden value="">
      Dietary Requirements:
    </option>
    <option value="Chicken">Chicken</option>
    <option value="Fish">Fish</option>
    <option value="Vegetarian">Vegetarian</option>
  </select>
*/
