import { useEffect, useRef, useState } from "react";

import classes from "./PhillipAndVivian.module.scss";
import globalStyles from "../../../GlobalStyles.module.css";
import Form from "../../../components/Form/Form";
import Rsvp from "../../../products/Rsvp/Rsvp";
import SaveTheDate from "../../../products/SaveTheDate/SaveTheDate";

const PhillipAndVivian = ({
  PRODUCT,
  CUSTOMER_NAME_1,
  CUSTOMER_NAME_2,
  CALENDAR_NAME,
  CALENDAR_DATE,
  CALENDAR_TIMEZONE,
  CALENDER_ADDRESS,
  ADDRESS,
  DATE_TEXT,
  RSVP_BY,
  FORM_NAME,
}) => {
  const [rsvpClicked, setRsvpClicked] = useState(false);
  const formContainerRef = useRef();

  useEffect(() => {
    if (rsvpClicked === true) {
      formContainerRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [rsvpClicked]);

  const handleRsvpClick = () => {
    setRsvpClicked(true);
    formContainerRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section className={classes["phillip-and-vivian"]}>
      <div className={classes["phillip-and-vivian-content"]}>
        <div>
          {PRODUCT === "saveTheDate" && <h2 className={globalStyles["brittany-signature-font"]}>Save the date...</h2>}
          <p style={{ margin: "0" }}>{PRODUCT === "rsvp" && "JOIN US "}FOR THE WEDDING OF</p>
          <h1 style={{ textTransform: "uppercase" }}>
            <span>{CUSTOMER_NAME_1}</span>
            <span> & </span>
            <span>{CUSTOMER_NAME_2}</span>
          </h1>
          <p className={classes.date}>{DATE_TEXT}</p>
          <p>{ADDRESS}</p>
          {PRODUCT === "rsvp" ? (
            <Rsvp deadline={classes.deadline} onClick={handleRsvpClick} RSVP_BY={RSVP_BY} />
          ) : (
            <SaveTheDate NAME={CALENDAR_NAME} DATE={CALENDAR_DATE} TIMEZONE={CALENDAR_TIMEZONE} ADDRESS={CALENDER_ADDRESS} />
          )}
        </div>
      </div>
      {rsvpClicked && <Form ref={formContainerRef} DATE_TEXT={DATE_TEXT} ADDRESS={ADDRESS} FORM_NAME={FORM_NAME} />}
    </section>
  );
};

export default PhillipAndVivian;
