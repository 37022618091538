import classes from "./CreamOnGold.module.scss";

const CreamOnGold = (props) => {
  return (
    <section className={classes["cream-on-gold-container"]}>
      <div>{props.children}</div>
    </section>
  );
};

export default CreamOnGold;
