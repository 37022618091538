import classes from "./Button.module.scss";

const Button = (props) => {
  const { type, onClick, id } = props;

  return (
    <button type={type} onClick={onClick} className={classes.button} id={id}>
      {props.children}
    </button>
  );
};

export default Button;
