import { AddToCalendarButton } from "add-to-calendar-button-react";

const SaveTheDate = ({ NAME, DATE, TIMEZONE, ADDRESS }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <AddToCalendarButton
        name={NAME}
        startDate={DATE}
        options={[
          "Apple",
          "Google",
          /* "iCal", */
          "Outlook.com",
          "Yahoo",
        ]} /* Apple, Google, iCal, Microsoft365, MicrosoftTeams, Outlook.com, Yahoo */
        timeZone={TIMEZONE} /* GMT by default! Always try to set it! */
        location={ADDRESS}
        size="6|6|1"
        hideBackground></AddToCalendarButton>
    </div>
  );
};

export default SaveTheDate;
