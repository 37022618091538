import "./App.css";
import CreamOnGold from "./pages/containers/CreamOnGold/CreamOnGold";

// import Home from "./pages/home";
// import DummyDesign from "./pages/designs/DummyDesign";
// import FloralWhiteOnBrown from "./pages/containers/FloralWhiteOnBrown/FloralWhiteOnBrown"; -- goes with Cam & Mitch
import PhillipAndVivian from "./pages/designs/PhillipAndVivian/PhillipAndVivian";

function App() {
  // COMMENT IN CORRECT PRODUCT

  // All products
  const BRANCH_NAME = "phillipandvivian-demo";
  const CUSTOMER_NAME_1 = "PHILLIP";
  const CUSTOMER_NAME_2 = "VIVIAN";
  const ADDRESS = (
    <>
      123 WEDDING LANE <br /> SW22 4LV
    </>
  );
  const DATE_TEXT = "SATURDAY 26TH AUGUST 2024";

  // Save The Date
  const PRODUCT = "saveTheDate";
  const CALENDAR_NAME = "The Marriage of Phillip & Vivian";
  const CALENDAR_DATE = "2024-08-26";
  const CALENDAR_TIMEZONE = "GMT";
  const CALENDAR_ADDRESS = "123 WEDDING LANE SW22 4LV";

  // RSVP
  // const PRODUCT = "rsvp";
  const RSVP_BY = "26TH MAY 2024";

  return (
    <>
      {/*  <Home /> */}
      {/* <DummyDesign /> */}

      <CreamOnGold>
        <PhillipAndVivian
          PRODUCT={PRODUCT}
          CUSTOMER_NAME_1={CUSTOMER_NAME_1}
          CUSTOMER_NAME_2={CUSTOMER_NAME_2}
          CALENDAR_NAME={CALENDAR_NAME}
          CALENDAR_DATE={CALENDAR_DATE}
          CALENDAR_TIMEZONE={CALENDAR_TIMEZONE}
          CALENDER_ADDRESS={CALENDAR_ADDRESS}
          ADDRESS={ADDRESS}
          DATE_TEXT={DATE_TEXT}
          RSVP_BY={RSVP_BY}
          FORM_NAME={BRANCH_NAME}
        />
      </CreamOnGold>
    </>
  );
}

export default App;
