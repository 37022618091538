// import Button from "../Button/Button";

const FormSubmitted = ({ rsvpResponse, firstName, setSubmitted, DATE_TEXT, ADDRESS }) => {
  return (
    <section>
      {rsvpResponse ? (
        <div className="thank-you">
          <h2>Thank-you!</h2>
          <p>We can't wait to celebrate with you{firstName.length < 20 && `, ${firstName}`}</p>
          <h3 className="">PHILLIP & VIVIAN</h3>
          <p style={{ fontWeight: "normal" }}>{DATE_TEXT}</p>
          <p>{ADDRESS}</p>
          {/* <Button type="button" onClick={() => setSubmitted(false)}>
            Add +1
          </Button> */}
        </div>
      ) : (
        <div className="sorry">
          <h2>Sorry you can't make it</h2>
          <p>Thanks for letting us know{firstName.length < 20 && `, ${firstName}`}</p>
          <h3>PHILLIP & VIVIAN</h3>
          <p style={{ fontWeight: "normal" }}>{DATE_TEXT}</p>
          <p>{ADDRESS}</p>
          {/* <Button type="button" onClick={() => setSubmitted(false)}>
            +1
          </Button> */}
        </div>
      )}
    </section>
  );
};

export default FormSubmitted;
